import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import fetchPathData from "@/lib/api/fetchPathData"
import { authEditor, validateEditor } from "@/lib/api/editor"
import { setUser, getUserToken, maybeValidUser } from "@/lib/helpers/user"
import { useAppContext } from "@/lib/contexts/appContext"
import { Spinner } from "@/components/common/loading/spinner"
import Button from "@/common/button/button"
import styles from "@/common/form/input.module.css"
import Block from "@/components/wrappers/block"
import cx from "classnames"

export default function LoginForm({ block }) {
  const [hasMounted, setMounted] = useState(false)
  const [error, setError] = useState(false)
  const [failure, setFailure] = useState(false)
  const { setPathData } = useAppContext()
  const router = useRouter()
  const methods = useForm({
    mode: "onTouched"
  })
  const { isSubmitting } = methods.formState
  const { attrs } = block

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      let token = null,
        username = null
      if (maybeValidUser()) {
        token = getUserToken()
      }

      if (window.location.search.length > 0) {
        const params = new URLSearchParams(window.location.search)
        token = params.get("admin-token")
        username = params.get("username")
      }

      if (token) {
        validateEditor(token).then((response) => {
          if (response.status === "ok") {
            setUser(response)
            if (block?.attrs?.redirect) {
              return router.push(block.attrs.redirect)
            }
            setContent(token)
          }
        })
      }

      if (username) {
        methods.setValue("user", username)
      }

      if (!token) {
        setMounted(true)
      }
    }
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setContent = async (token, callback) => {
    const pathData = await fetchPathData({ path: router.asPath }, token)
    if (block?.attrs?.redirect && pathData.status === 308) {
      router.push(block?.attrs?.redirect)
      return null
    }
    if (pathData.status === 200) {
      setPathData(pathData)
    } else {
      setFailure(true)
    }
    if (typeof callback === "function") {
      callback()
    }
  }

  const onSubmit = async ({ user, password }) => {
    error && setError(false)

    const auth = await authEditor({ user, password })

    if (auth.status === "ok") {
      setUser(auth)

      if (block?.attrs?.redirect) {
        return router.push(block.attrs.redirect)
      } else {
        setContent(auth?.credentials?.token)
      }
    } else {
      setError(true)
    }
  }

  if (!hasMounted) {
    return (
      <div className="p-16 pb-20 text-center">
        <Spinner
          size="large"
          borderClass="border-green-100"
          spinColor="#ECF2CA"
          className="mx-auto"
        />
      </div>
    )
  }

  return (
    <Block block={block} width="column">
      <form
        className="max-w-md mx-auto"
        onSubmit={methods.handleSubmit(onSubmit)}>
        <input
          autoComplete="username"
          className={cx("mb-4", styles.input)}
          {...methods.register("user", { required: true })}
          placeholder={attrs.labelUsername}
        />
        <input
          type="password"
          autoComplete="current-password"
          className={cx("mb-4", styles.input)}
          {...methods.register("password", { required: true })}
          placeholder={attrs.labelPassword}
        />
        <Button
          size="small"
          theme="lime"
          disable={isSubmitting}
          type="submit"
          className="w-full">
          {isSubmitting ? "Hämtar..." : attrs.labelLogin}
        </Button>
        {error && (
          <p className="mt-4 text-sm text-center text-red-100">
            Fel användarnamn/lösenord, vänligen försök igen.
          </p>
        )}
        {failure && (
          <p className="mt-4 text-sm text-center text-red-100">
            Ett fel uppstod, vänligen försök igen.
          </p>
        )}
      </form>
    </Block>
  )
}
